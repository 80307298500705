<template>
  <div>
    <b-card no-body class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("MENU.ITEM.PROTOCOLS.PROTOCOLS") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LABELS.LIST") }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <button
            class="btn btn-warning font-weight-bolder mr-2"
            @click="handleExport"
          >
            Експорт
          </button>
          <router-link
            v-permission="['protocols-create']"
            to="add"
            class="btn btn-light-success font-weight-bolder"
            ><i class="flaticon2-plus"></i>
            {{ $t("LABELS.ADD_NEW") }}</router-link
          >
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <SearchTools
          :searchQuery.sync="searchQuery"
          @do-search="doSearch"
          @do-clear="doClear"
        >
          <template v-slot:prepend>
            <b-col class="pb-0" :md="6">
              <b-form-group id="date-from-group" label-for="date-from-input">
                <FormDatepicker
                  id="date-to-input"
                  type="text"
                  name="date_from"
                  :i18n="{ placeholder: 'FORM_LABELS.DATE_FROM' }"
                  :validateState="{}"
                  v-model="filters.dateFrom"
                ></FormDatepicker>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="6">
              <b-form-group id="date-from-group" label-for="date-from-input">
                <FormDatepicker
                  id="date-from-input"
                  type="text"
                  name="date_to"
                  :i18n="{ placeholder: 'FORM_LABELS.DATE_TO' }"
                  :validateState="{}"
                  v-model="filters.dateTo"
                ></FormDatepicker>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="3">
              <b-form-group id="assignors-group" label-for="assignors-input">
                <FormSelect
                  id="assignors-input"
                  :placeholder="$t('FORM_LABELS.ASSIGNOR')"
                  type="select"
                  name="assignors"
                  :options="assignors"
                  clearable="true"
                  v-model="filters.assignorId"
                  @input="fetchAssignorLocations"
                ></FormSelect>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="3">
              <b-form-group id="locations-group" label-for="locations-input">
                <FormSelect
                  id="locations-input"
                  type="select"
                  :placeholder="$t('FORM_LABELS.LOCATION')"
                  name="locations"
                  :options="locations"
                  clearable="true"
                  v-model="filters.locationId"
                ></FormSelect>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="3">
              <b-form-group id="invoices-group" label-for="invoices-input">
                <FormSelect
                  id="invoice-filter-input"
                  type="select"
                  :placeholder="$t('FORM_LABELS.INVOICE_FILTER')"
                  name="invoice-filter"
                  :options="[
                    { name: 'Само фактурирани', id: 1 },
                    { name: 'Само нефактуриран', id: 0 }
                  ]"
                  clearable="true"
                  v-model="filters.invoiceFilter"
                ></FormSelect>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="3">
              <b-form-group id="invoices-group" label-for="invoices-input">
                <FormSelect
                  id="finished-filter-input"
                  type="select"
                  :placeholder="$t('FORM_LABELS.FINISHED_FILTER')"
                  name="finished-filter"
                  :options="[
                    { name: 'Само финализирани', id: 1 },
                    { name: 'Само нефинализирани', id: 0 }
                  ]"
                  clearable="true"
                  v-model="filters.finishedFilter"
                ></FormSelect>
              </b-form-group>
            </b-col>
          </template>
        </SearchTools>
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
          @update:options="getDataFromApi()"
          class="px-6 elevation-1"
        >
          <template v-slot:[`item.name`]="{ item }">
            <span v-if="item.name">
              {{ item.name }}/{{ item.protocolDate | moment("DD.MM.YYYY") }}
            </span>
          </template>

          <template v-slot:[`item.dateFrom`]="{ item }">
            {{ item.dateFrom | moment("DD.MM.YYYY") }}
          </template>

          <template v-slot:[`item.dateTo`]="{ item }">
            {{ item.dateTo | moment("DD.MM.YYYY") }}
          </template>

          <template v-slot:[`item.completed`]="{ item }">
            {{ item.completed == 0 ? "Не" : "Да" }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-end">
              <span
                v-if="
                  item.completed &&
                    checkIfUserHasPermission(['protocols-update'])
                "
              >
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      color="warning"
                      fab
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      @click="handlePrintClick(item.id)"
                    >
                      <v-icon>mdi-printer</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("LABELS.PRINT") }}</span>
                </v-tooltip>
              </span>
              <router-link
                v-if="checkIfUserHasPermission(['protocols-update'])"
                :to="{ name: 'edit-protocols', params: { id: item.id } }"
              >
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>
                        {{
                          item.completed
                            ? !item.hasInvoice
                              ? "mdi-file-document-edit-outline"
                              : "mdi-eye"
                            : "mdi-pencil"
                        }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{
                      item.completed
                        ? !item.hasInvoice
                          ? $t("BUTTON.ADD_INVOICE")
                          : $t("BUTTON.PREVIEW")
                        : $t("LABELS.EDIT")
                    }}
                  </span>
                </v-tooltip>
              </router-link>
            </div>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
    </b-card>
  </div>
</template>

<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import SearchTools from "@/core/components/table/SearchTools.vue";
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import FormSwitch from "@/view/content/forms/components/FormSwitch.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";

import {
  FETCH_PROTOCOLS,
  GENERATE_PDF_URL,
  GENERATE_EXPORT_PROTOCOLS_URL
} from "@/modules/protocols/store/protocol.module";
import { FETCH_ASSIGNORS } from "@/modules/assignors/store/assignors.module";
import { FETCH_ASSIGNOR_LOCATIONS } from "@/modules/assignorslocation/store/location.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import { BASE_API_URL } from "@/core/config/config";

export default {
  name: "ProtocolList",
  mixins: [permissionMixin],
  components: {
    SearchTools,
    FormSelect,
    FormSwitch,
    FormDatepicker
  },
  data() {
    return {
      baseUrl: BASE_API_URL,
      isPageLoading: true,
      tableLoading: true,
      items: [],
      totalItems: 100,
      searchQuery: "",
      options: {
        sortBy: ["protocolDate"],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 30,
        totalItems: 100
      },
      filters: {
        assignorId: null,
        locationId: null,
        invoiceFilter: null,
        finishedFilter: null
      },
      rowsPerPageItems: [10, 20, 30, 40, 50],
      columns: {},
      cancelSource: null,
      headers: [
        {
          text: this.$i18n.t("FORM_LABELS.INVOICE_NUMBER"),
          value: "invoiceNumber",
          sortable: false
        },
        {
          text: this.$i18n.t("MENU.ITEM.ASL.ASSIGNOR"),
          value: "assignorName",
          sortable: false
        },
        {
          text: this.$i18n.t("MENU.ITEM.ASL.LOCATION"),
          value: "locationName",
          sortable: false
        },
        { text: this.$i18n.t("FORM_LABELS.PROTOCOL"), value: "name" },
        { text: this.$i18n.t("FORM_LABELS.FROM"), value: "dateFrom" },
        { text: this.$i18n.t("FORM_LABELS.TO"), value: "dateTo" },
        { text: this.$i18n.t("FORM_LABELS.TOTAL_PRICE"), value: "totalAmount" },
        {
          align: "right",
          text: this.$i18n.t("MENU.ITEM.ACTIONS"),
          value: "actions",
          sortable: false,
          width: "10%"
        }
      ],
      assignors: [],
      locations: []
    };
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.PROTOCOLS.PROTOCOLS"),
        route: { name: "list-protocols" }
      },
      { title: this.$i18n.t("MENU.ITEM.ASSETS.LIST") }
    ]);

    this.$store
      .dispatch(
        FETCH_ASSIGNORS,
        this.$url.transformParams({
          onlySelectValues: true,
          selectValueKey: "company_name"
        })
      )
      .then(data => {
        this.assignors = data.data.items;
      });

    this.fetchAssignorLocations();
  },
  computed: {
    params() {
      return {
        ...this.options,
        ...this.filters,
        query: this.searchQuery
      };
    },
    tableHeaders() {
      let vm = this;
      return vm.headers;
    }
  },
  methods: {
    fetchAssignorLocations(id = null) {
      this.$store
        .dispatch(
          FETCH_ASSIGNOR_LOCATIONS,
          this.$url.transformParams({
            assignorId: id
          })
        )
        .then(data => {
          this.locations = data.data.items;
          if (this.locations.length === 1) {
            this.filters.locationId = this.locations[0].id;
          } else {
            this.filters.locationId = null;
          }
          this.locations.unshift({
            id: 0,
            name: this.$i18n.t("FORM_LABELS.NO_LOCATION")
          });
        });
    },
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.searchQuery = "";
      Object.keys(vm.filters).forEach(key => {
        vm.filters[key] = null;
      });
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;
      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;

      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      // console.log(this.params);
      let apiParams = vm.$url.transformParams(params);
      // console.log(apiParams);
      vm.$store
        .dispatch(FETCH_PROTOCOLS, apiParams)
        .then(data => {
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;

            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "list-protocols" });
          }
        });
    },
    handlePrintClick(id) {
      this.$store.dispatch(GENERATE_PDF_URL, id);
    },
    handleExport() {
      this.$store.dispatch(
        GENERATE_EXPORT_PROTOCOLS_URL,
        this.$url.transformParams({
          ...this.filters,
          query: this.searchQuery
        })
      );
    }
  }
};
</script>
